import { Flex, FlexProps } from '@chakra-ui/react'
import { FC } from 'react'

interface Props extends FlexProps {
  bgColor?: string
  title: string
  value: string
  textColor?: string
}

const ProjectCategory: FC<Props> = ({ title, bgColor = '#FF6C4A', textColor = 'white', value, ...props }) => {
  return (
    <Flex {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="90px" height="90px" viewBox="-10 -20 130 130">
        <circle cx="55" cy="52" r="50" style={{ fill: bgColor, stroke: bgColor, strokeWidth: 16 }} />
        <path id="top-sector" style={{ fill: 'none', stroke: 'none' }} d="M 9,50 A 46,46.5 0 0 1 100.5,50" />
        <text textAnchor="middle">
          <textPath
            xlinkHref="#top-sector"
            startOffset="50%"
            style={{ fontSize: '14px', fill: textColor, fontWeight: 400, textTransform: 'uppercase' }}
          >
            {title}
          </textPath>
        </text>
        <text
          x="43%"
          y="49%"
          startOffset="50%"
          textAnchor="middle"
          style={{ fontSize: '18px', fill: textColor, fontWeight: 700 }}
        >
          {value}
        </text>
      </svg>
    </Flex>
  )
}

export default ProjectCategory
