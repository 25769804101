import { IconProps } from '@chakra-ui/react'
import { FC } from 'react'

import { categoriesMapping } from '@miimosa/common'
import * as categoriesIcons from '@miimosa/design-system/components/icons/categories'

interface Props extends IconProps {
  category: string
  isDark?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProjectCategory: FC<Props> = ({ category, isDark, css, ...props }) => {
  // css is excluded to avoir type error below :/
  const bgColor = isDark ? 'primary.DEFAULT' : 'bg.0'
  const iconColor = isDark ? 'bg.0' : 'primary.DEFAULT'
  const iconName = categoriesMapping[category] as keyof typeof categoriesIcons
  const Icon = categoriesIcons[iconName] as FC<IconProps>

  // fallback if a new category has no icon related
  if (!Icon) return null

  return <Icon bgColor={bgColor} color={iconColor} borderRadius="50%" zIndex="1" {...props} />
}

export default ProjectCategory
