import { ButtonProps, Button, useBreakpointValue } from '@chakra-ui/react'
import { FC } from 'react'

import { preventingDefault } from '@miimosa/common'
import { Icon } from '@miimosa/design-system'

interface Props extends ButtonProps {
  close: () => void
}

const CloseCard: FC<Props> = ({ close, ...props }) => {
  const iconSize = useBreakpointValue({ base: 'iconXS', md: 'iconSM' }, { fallback: 'md' })

  return (
    <Button size={iconSize} variant="iconW" onClick={preventingDefault(close)} {...props}>
      <Icon name="Close" size="4xs" />
    </Button>
  )
}

export default CloseCard
