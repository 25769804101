import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

interface Props {
  isSuccess?: boolean
  progress: number
  collected: number
  isSmall?: boolean
  goalType: string
  collectType: string
  goalAmount?: number
}

const ProjectProgressBar: FC<Props> = ({
  isSuccess,
  collected,
  progress,
  goalType,
  collectType,
  isSmall = false,
  goalAmount,
}) => {
  const { t: tCommon } = useTranslation()
  const barColor = isSuccess ? 'secondary.SECOND' : 'secondary.DEFAULT'

  return (
    <Flex direction="column" mt={isSmall ? '8px' : '16px'} width="100%">
      <Flex bgColor="primary_grey.DEFAULT" width="100%" height="8px" borderRadius="16px" mb={isSmall ? '4px' : '8px'}>
        <Flex bgColor={barColor} height="8px" borderRadius="16px" w={`${isSuccess ? '100' : progress}%`}></Flex>
      </Flex>
      <Flex align="center" justify="space-between">
        <Text size={isSmall ? '3xs' : 'xs'}>
          {goalAmount
            ? `Objectif de ${goalAmount} €`
            : tCommon(goalType === 'quantity' && collectType === 'donation' ? 'presales' : 'collected', {
                val: collected,
              })}
        </Text>
        <Text size={isSmall ? '3xs' : 'sm'} fontWeight="bold">
          {progress}%
        </Text>
      </Flex>
    </Flex>
  )
}

export default ProjectProgressBar
