import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import { getProjectStatusString } from '@miimosa/design-system'

interface Props {
  isSoon: boolean
  isDone: boolean
  isSuccess: boolean
  endsAt: string
  progress?: number
  goalType: string
  collectType: 'donation' | 'lending'
  isSmall?: boolean
  interestsRatio?: number
  isCip: boolean // not optional to make sure it is passed
}

const ProjectProgress: FC<Props> = ({
  isSoon,
  isDone,
  isSuccess,
  endsAt,
  // progress,
  collectType,
  interestsRatio,
  // isCip,
  isSmall = false,
}) => {
  const { t: tCommon } = useTranslation('common')

  const mainColor = (isSuccess && 'secondary.SECOND') || 'secondary.DEFAULT'
  const tColor = isSuccess ? 'white' : undefined
  const timingString = getProjectStatusString(isDone, isSoon, isSuccess, new Date(endsAt), tCommon)
  // const user = useContext(UserContext)
  const px = isSmall ? '8px' : '11px'
  const shouldDisplayRatio = collectType === 'lending' && !!interestsRatio

  return (
    <Flex
      align="center"
      justify="start"
      mt="8px"
      h="20px"
      borderRadius="4px"
      overflow="hidden"
      bgColor="primary_grey.DEFAULT"
      mr="auto"
    >
      <Text color={tColor} bgColor={mainColor} py="4px" px={px} size={isSmall ? '4xs' : '3xs'}>
        {timingString}
      </Text>
      <Text py="4px" px={px} size={isSmall ? '4xs' : '3xs'}>
        {tCommon(collectType)}
      </Text>
      {shouldDisplayRatio && (
        <Text color={mainColor} size="xs" px="2">
          {(interestsRatio * 100)?.toFixed(2).replace(/\./g, ',')}%
        </Text>
      )}
    </Flex>
  )
}

export default ProjectProgress
