import { Box, BoxProps, Flex, Heading, Text } from '@chakra-ui/react'
import { FC } from 'react'

import { Image } from '@miimosa/design-system'
import { directusAsset } from '@miimosa/directus'

import { ProjectLocation } from './components'

interface Props extends BoxProps {
  title: string
  description: string
  place: string
  image: { filename_disk: string }
}

const FakeProjectCard: FC<Props> = ({ title, description, place, image, ...props }) => {
  const Card = () => (
    <Flex
      direction="column"
      borderRadius="4px"
      alignItems="stretch"
      overflow="hidden"
      borderWidth="1px"
      borderColor="transparent"
      sx={{
        _hover: {
          borderWidth: '1px',
          borderColor: 'primary.DEFAULT',
          '.cta': {
            visibility: 'visible',
          },
        },
      }}
      width={{ base: '100%', md: '345px' }}
      {...props}
    >
      <Flex
        position="relative"
        h={'225px'}
        flex="none"
        alignItems="center"
        direction="column"
        justify="center"
        backgroundColor="#F6F5F1"
      >
        {image && <Image src={directusAsset(image)} fill style={{ objectFit: 'cover' }} alt="" quality={100} />}
      </Flex>

      <Flex direction="column" p={5} flex="1 1 0%" bgColor="white">
        <Heading className="line-clamp2" size="md" height="48px" overflow="hidden" color="text" mb="2">
          {title}
        </Heading>
        <Box mt="2" height="60px">
          <Text as="span" color="text" size="xs" noOfLines={3}>
            {description}
          </Text>
        </Box>
        <Flex align="center" justify="space-between" mt="4">
          <ProjectLocation place={place} />
        </Flex>
      </Flex>
    </Flex>
  )

  return <Card />
}

export default FakeProjectCard
