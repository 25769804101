import { Flex, Text } from '@chakra-ui/react'

import { Icon } from '@miimosa/design-system'

const ProjectLocation = ({ place, isSmall = false }: { place: string; isSmall?: boolean }) => {
  return (
    <Flex align="center">
      <Icon name="FranceOutline" size={isSmall ? '5xs' : '4xs'} color="primary.TERTIARY" />
      <Text size={isSmall ? '4xs' : '3xs'} fontFamily="innocent-headline_bold" color="primary.TERTIARY" ml="6px">
        {place}
      </Text>
    </Flex>
  )
}

export default ProjectLocation
